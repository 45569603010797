import { Box, Button, Center, Flex, HStack, Input, Spinner, Text, VStack } from '@chakra-ui/react';
import { ServerUser } from '@karya/core';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import * as jose from 'jose';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/app/Store';
import { backendApi } from 'src/features/apiSlice';
import CFImage from 'src/helpers/CloudfareImage';
import { useFlagEffect } from 'src/hooks/Misc';
import { useApiToastEffect } from 'src/hooks/Toasts';
import { ColorMap } from 'src/themes/Attributes';
import { ButtonVariant } from 'src/themes/ButtonVariants';

export function LoginRegister(props: any) {
  // Navigation
  const navigate = useNavigate();

  // Current project
  const currProject = useAppSelector((state) => state.currentProject.project);

  // Check server user query
  const checkServerUserQuery = backendApi.useGetServerUserQuery();

  // Login query
  const [loginTrigger, loginQuery] = backendApi.useLoginUserMutation();

  // Signup query
  const [signupUserTrigger, signupUserQuery] = backendApi.useSignupUserMutation();

  // Auto-login tried
  const [isAutoLoginTried, setAutoLoginTried] = useState(false);

  // Login Toasts
  useApiToastEffect({
    isLoading: loginQuery.isLoading,
    isError: loginQuery.isError,
    isSuccess: loginQuery.isSuccess,
    successToast: 'Successfully Logged In',
    errorToast: 'Authentication Failed',
  });

  // Signup Toasts
  useApiToastEffect({
    isLoading: signupUserQuery.isLoading,
    isError: signupUserQuery.isError,
    isSuccess: signupUserQuery.isSuccess,
    successToast: 'Account Created Successfully',
    errorToast: 'Authentication Failed',
  });

  /**
   * Successful Google login
   */
  const onGoogleLoginSuccess = async (credentialResponse: CredentialResponse) => {
    const id_token = credentialResponse.credential;
    if (id_token == null) {
      onGoogleLoginFailure('Invalid token');
      return;
    }

    // Attempt login
    await loginTrigger({ google_id_token: id_token });
  };

  /**
   * Failed google login
   * @param error Error message
   */
  const onGoogleLoginFailure = (error: string) => {};

  /**
   * Signin form
   */
  const signinForm = (
    <VStack>
      <Text>
        If you are an existing user,
        <br />
        login using your Google account
      </Text>
      <GoogleLogin onSuccess={onGoogleLoginSuccess} onError={() => onGoogleLoginFailure('error')} />
    </VStack>
  );

  /**
   * Signup State
   */
  const [access_code, setAccessCode] = useState('');
  const [idToken, setIdToken] = useState('');
  const [isIdTokenValid, setIsIdTokenValid] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const onGoogleSignupSuccess = (credentialResponse: CredentialResponse) => {
    const id_token = credentialResponse.credential;
    if (id_token == null) {
      setIsIdTokenValid(false);
      onGoogleLoginFailure('Invalid token');
      return;
    }

    const payload = jose.decodeJwt(id_token);
    const givenName = payload['given_name'] as string;
    const familyName = payload['family_name'] as string;
    const full_name = `${givenName ?? ''}  ${familyName ?? ''}`;

    const email = payload['email'] as string;

    setName(full_name);
    setEmail(email);
    setIdToken(id_token);
    setIsIdTokenValid(true);
  };

  const handleRegisterUser = async () => {
    const server_user: ServerUser = {
      full_name: name,
      email,
    };
    await signupUserTrigger({
      google_id_token: idToken,
      access_code,
      server_user,
    });
  };

  /**
   * Signup form
   */
  const signupForm = (
    <VStack>
      <Text>If you a new user, register with the 16-character access code and your google account.</Text>
      <Input
        id="access_code"
        placeholder="Enter 16-character Access Code"
        value={access_code}
        width="80%"
        onChange={(e) => setAccessCode(e.currentTarget.value)}
      />
      <GoogleLogin onSuccess={onGoogleSignupSuccess} onError={() => onGoogleLoginFailure('error')} text="signup_with" />

      {isIdTokenValid && (
        <Box
          backgroundColor={ColorMap.primaryDark}
          textColor={ColorMap.white}
          padding="10px"
          paddingLeft="30px"
          width="80%"
          rounded="10px"
          textAlign="left"
        >
          <Text>
            {name} ({email})
          </Text>
        </Box>
      )}

      <Button
        variant={ButtonVariant.submit}
        width="80%"
        onClick={handleRegisterUser}
        isLoading={signupUserQuery.isLoading}
      >
        Register
      </Button>
    </VStack>
  );

  /**
   * Redirect to dataset page or project page if login successful
   */
  useFlagEffect(loginQuery.isSuccess || signupUserQuery.isSuccess || checkServerUserQuery.isSuccess, () => {
    if (currProject) {
      navigate('/datasets');
    } else {
      navigate('/projects');
    }
  });

  /**
   * Set autologin tried status flag
   */
  useFlagEffect(checkServerUserQuery.isSuccess || checkServerUserQuery.isError, () => setAutoLoginTried(true));

  /**
   * Before rendering, check if a user has already logged in
   */
  if (!isAutoLoginTried && checkServerUserQuery.isLoading) {
    return (
      <Center height="100%" width="100%">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Flex height="100%">
      {/* Logo box */}
      <Box flex={1} backgroundColor={'#191e29'} height={'100%'}>
        <Center height="85%">
          <CFImage cfsrc="leyu_full_logo" width="260px" />
        </Center>
        <Center height="15%">
          <HStack gap="10px">
            <Text color={ColorMap.impactGreen} fontSize={'20px'}>
              Powered by
            </Text>
            <CFImage cfsrc="karya-logo-name-horizontal" width="110px" />
          </HStack>
        </Center>
      </Box>

      {/* Form box */}
      <Box flex={1} backgroundColor={ColorMap.stockGray}>
        <Center height="100%">
          <VStack width="60%">
            <Box rounded="5px" padding="10px" backgroundColor={ColorMap.white} width="100%" textAlign="center">
              Karya Admin Portal
            </Box>

            {/* Login form */}
            <Box rounded="5px" padding="10px" backgroundColor={ColorMap.white} width="100%" textAlign="center">
              {signinForm}
            </Box>

            {/* Signup form */}
            <Box rounded="5px" padding="10px" backgroundColor={ColorMap.white} width="100%" textAlign="center">
              {signupForm}
            </Box>
          </VStack>
        </Center>
      </Box>
    </Flex>
  );
}
